import { TableConfig } from '@app/shared/table/TableConfig';
import { CapitalizePipe } from '@app/shared/pipes/capitalize.pipe';
export class ManagecoacheTableConfig extends TableConfig {
  constructor(private capitalize?: CapitalizePipe) {
    super();
    this.allowedColumns = ['name', 'email', 'status'];
    this.capitalize = new CapitalizePipe();

    this.columns = {
      name: {
        code: 'name',
        text: 'Coach Name',
        getValue: (ele: any) => {
          const fullName = this.capitalize.transform(
            ele[this.columns.name.code]
          );
          return fullName.length > 12
            ? `${fullName.substring(0, 12)}`
            : fullName;
        }
      },

      email: {
        code: 'email',
        text: 'Email',
        getValue: (ele: any) => {
          return ele[this.columns.email.code] || 'N/A';
        }
      },

      status: {
        code: 'status',
        text: 'Status',
        getValue: (ele: any) => {
          return ele[this.columns.status.code];
        }
      },
      action: {
        code: 'action',
        text: 'Action',
        getValue: (ele: any) => {
          return ele[this.columns.action.code];
        }
      }
    };
  }
}
