import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root' // Makes the service available application-wide
})
export class ParentDataService {
  // BehaviorSubject to hold and share the children data
  private childrenData = new BehaviorSubject<any[]>([]);
  children$ = this.childrenData.asObservable();

  // Method to set children data
  setChildren(children: any[]) {
    this.childrenData.next(children);
  }

  // Method to get the current children data
  getChildren() {
    return this.childrenData.getValue();
  }
}
