import * as CryptoJS from 'crypto-js';

export class SecureStorage {
  private static encryptionKey = 'your-secure-key'; // Replace with a strong key

  // Encrypt and store data
  static setItem(key: string, value: any): void {
    const encryptedValue = CryptoJS.AES.encrypt(
      JSON.stringify(value),
      this.encryptionKey
    ).toString();
    localStorage.setItem(key, encryptedValue);
  }

  // Retrieve and decrypt data
  static getItem(key: string): any {
    const encryptedValue = localStorage.getItem(key);
    if (!encryptedValue) return null;

    try {
      const bytes = CryptoJS.AES.decrypt(encryptedValue, this.encryptionKey);
      const decryptedValue = bytes.toString(CryptoJS.enc.Utf8);
      return JSON.parse(decryptedValue);
    } catch (error) {
      console.error('Error decrypting data from localStorage:', error);
      return null;
    }
  }

  // Remove an item from storage
  static removeItem(key: string): void {
    localStorage.removeItem(key);
  }
}
