import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';

const routes = {
  getPersonalProfileDetails: () => `/profile/personal_details`,
  getProfessionalProfileDetails: () => `/profile/professional_details`,
  updatePersonalProfileDetails: () => `/update-details/personal_details`,
  updateAvatar: () => `/update-avatar`,
  getEmploymentContractList: () => '/employment-contract/list',
  deleteContract: (id: string) => `/employment-contract/${id}`,
  getCoachRole: () => '/profile/getcoach/role',
  sendOtpToVerifyEmail: (id: string, dataToVerify: string) =>
    `/otp-for-email-verify/${id}/${dataToVerify}`,
  sendOtpToVerifyMobile: (id: string, dataToVerify: string) =>
    `/otp-for-mobile-verify/${id}/${dataToVerify}`,
  verifyOtp: (query: string) => `/otp/verification/?${query}`,
  deleteAccount: (user_id: string) => `/member/delete/${user_id}`,
  getPublicProfileDetails: (params: string) =>
    `/member/public/profile/${params}`
};

interface FindOtpContext {
  name: string;
  email: string;
  otp: string;
  mobile_number: string;
}
interface OtpResponseContext {
  status: string;
  message: string;
  data: {
    records: {
      otp_id: string;
    }[];
  };
}
interface GetPublicProfileDetailsContext1 {
  user_id: string;
}
export interface GetPublicProfileDetailsResponseContext {
  status: string;
  message: string;
  data: {
    first_name: string;
    last_name: string;
    email: string;
    phone: string;
    country: { id: string; name: string };
    state: { id: string; name: string };
    avatar_url: string;
    position: {
      _id: string;
      name: string;
      id: string;
      priority: string;
    }[];

    district: { id: string; name: string };
    club_academy_details: {
      head_coache_name: string;
      head_coache_phone: string;
      head_coache_email: string;
    };
    dob: string;
    height: { feet: string; inches: string };
    institute: {
      school: string;
      college: string;
      university: string;
    };
    player_type: string;
    strong_foot: string;
    weak_foot: string;
    weight: string;
    bio: string;
    social_profiles: {
      facebook: string;
      youtube: string;
      twitter: string;
      instagram: string;
      linked_in: string;
    };
    association: string;
    association_other: string;
    associated_club_academy: string;
    former_club_academy: string;
    gender: string;
    member_type: string;
    profile_status: { status: string; remarks: string };
    is_followed: boolean;
    footmate_status: string;
    name: string;
    type: string;
    trophies: {
      _id: string;
      name: string;
      year: string;
      position: string;
      id: string;
    }[];
    top_signings: {
      _id: string;
      name: string;
    }[];
    contact_person: {
      _id: string;
      designation: string;
      name: string;
      email: string;
      mobile_number: string;
    }[];
    founded_in: string;
    league: string;
    league_other: string;
    mobile_number: string;
    short_name: string;
    address: {
      full_address: string;
      pincode: string;
    };
    stadium_name: string;
  };
}
@Injectable({
  providedIn: 'root'
})
export class ViewEditProfileService {
  constructor(private httpClient: HttpClient) {}

  deleteAccount(user_id: string): Observable<any> {
    return this.httpClient.get<any>(routes.deleteAccount(user_id));
  }
  deleteContract(id: string): Observable<any> {
    return this.httpClient.delete<any>(routes.deleteContract(id));
  }
  getPersonalProfileDetails(): Observable<any> {
    return this.httpClient.get<any>(routes.getPersonalProfileDetails());
  }
  getCoachRole(): Observable<any> {
    return this.httpClient.get<any>(routes.getCoachRole());
  }
  getProfessionalProfileDetails(): Observable<any> {
    return this.httpClient.get<any>(routes.getProfessionalProfileDetails());
  }
  updatePersonalProfileDetails(body: any): Observable<any> {
    return this.httpClient.put<any>(
      routes.updatePersonalProfileDetails(),
      body
    );
  }
  updateAvatar(body: any): Observable<any> {
    return this.httpClient.put(routes.updateAvatar(), body);
  }
  getEmploymentContractList(): Observable<any> {
    return this.httpClient.get<any>(routes.getEmploymentContractList());
  }

  verifyEmail(id: string, dataToVerify: string): Observable<any> {
    return this.httpClient.get<any>(
      routes.sendOtpToVerifyEmail(id, dataToVerify)
    );
  }
  verifyMobile(id: string, dataToVerify: string): Observable<any> {
    return this.httpClient.get<any>(
      routes.sendOtpToVerifyMobile(id, dataToVerify)
    );
  }

  verifyOtp(context: FindOtpContext): Observable<OtpResponseContext> {
    let query = '';
    console.log('co');
    if (context['email']) {
      query += 'email=' + context['email'];
    }
    if (context['mobile_number']) {
      query += 'mobile_number=' + context['mobile_number'];
    }
    if (context['otp']) {
      query += '&otp=' + context['otp'];
    }
    if (context['name']) {
      query += '&name=' + context['name'];
    }
    return this.httpClient.get<OtpResponseContext>(routes.verifyOtp(query));
  }
  getPublicProfileDetails(
    context: GetPublicProfileDetailsContext1
  ): Observable<GetPublicProfileDetailsResponseContext> {
    let params = '';
    if (context['user_id']) {
      params += context['user_id'];
    }
    return this.httpClient.get<GetPublicProfileDetailsResponseContext>(
      routes.getPublicProfileDetails(params)
    );
  }
}
