import { Component, OnInit } from '@angular/core';
import { MatTableDataSource } from '@angular/material/table';
import { environment } from '@env/environment';
import { SecureStorage } from '../../SecureStorage';
@Component({
  selector: 'app-attendance-history-view',
  templateUrl: './attendance-history-view.component.html',
  styleUrls: ['./attendance-history-view.component.scss']
})
export class AttendanceHistoryViewComponent implements OnInit {
  attendanceData: any = [];
  selectedDate: string = '';
  public dataSource = new MatTableDataSource([]);
  environment = environment;
  ngOnInit(): void {
    // Access the state directly from the browser's history
    const state = history.state;

    if (state && state.attendanceData) {
      this.attendanceData = state.attendanceData.data || [];
      this.selectedDate = state.selectedDate || '';
      SecureStorage.setItem('attendanceData', this.attendanceData);
      SecureStorage.setItem('selectedDate', this.selectedDate);
    } else {
      this.attendanceData = SecureStorage.getItem('attendanceData') || [];
      this.selectedDate = SecureStorage.getItem('selectedDate') || '';
    }
    this.dataSource = new MatTableDataSource(this.attendanceData);
  }
  onImageError(event: Event) {
    const target = event.target as HTMLImageElement;
    target.src = 'http://localhost:3000/uploads/avatar/user-avatar.png'; // Replace with your fallback URL
  }
  capitalizeFirst(value: string): string {
    if (!value) return '';
    return value.charAt(0).toUpperCase() + value.slice(1);
  }
}
