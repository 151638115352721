import { Component, OnInit } from '@angular/core';
import {
  AttendanceService,
  GetPublicProfileDetailsResponseContext
} from '../attendance.service';
import { ToastrService } from 'ngx-toastr';
@Component({
  selector: 'app-child-attendance',
  templateUrl: './child-attendance.component.html',
  styleUrls: ['./child-attendance.component.scss']
})
export class ChildAttendanceComponent implements OnInit {
  children: any[] = [];
  selectedUserId: string = null;
  user_type = 'parent';
  userId: string;
  send_to = '';
  dataSourceForAttendance: any[] = [];
  displayedColumns: string[] = ['name', 'email', 'phone', 'date', 'status'];
  constructor(
    private _attendanceService: AttendanceService,
    private _toastrService: ToastrService
  ) {}

  ngOnInit() {
    this.children = JSON.parse(localStorage.getItem('children'));
    const users = JSON.parse(localStorage.getItem('children'));

    if (users && users.length > 0) {
      this.userId = users[0].user_id;
      console.log('First User ID:', this.userId); // Logs the user_id of the first user
    } else {
      console.error('User list is empty or undefined.');
    }

    this.getPlayerAttendanceDetails();
  }

  getPlayerAttendanceDetails() {
    let data = {
      user_id: this.userId
    };

    this._attendanceService.getPlayerAttendanceDetails(data).subscribe(
      response => {
        // Ensure response.data is an array
        if (Array.isArray(response.data)) {
          this.dataSourceForAttendance = response.data.map(item => {
            // Extract player data and details
            const playerData =
              item.player_data.length > 0 ? item.player_data[0] : null;
            const playerDetails = playerData || {};

            // Extract training center details
            const trainingCenterDetails = item.training_center_details || {};

            return {
              traning_center_name:
                trainingCenterDetails.traning_center_name || 'N/A',
              start_time: trainingCenterDetails.start_time || 'N/A',
              end_time: trainingCenterDetails.end_time || 'N/A',
              address: trainingCenterDetails.full_address || 'N/A', // Assuming `phone` is equivalent to `player_user_id`
              status: playerDetails.status || 'N/A',
              date: playerDetails.date || 'N/A'
            };
          });
        } else {
          this._toastrService.error('Invalid data format received from API');
        }
      },
      error => {
        //this._toastrService.error('Error', error.error.message);
      }
    );
  }
  onTabChange(event: any): void {
    const selectedIndex = event.index;

    // Check if a valid child user tab is clicked
    if (selectedIndex >= 0 && selectedIndex < this.children.length) {
      this.userId = this.children[selectedIndex].user_id; // Fetch userId based on the index
      console.log('Selected User ID:', this.userId);
      this.getPlayerAttendanceDetails();
      this.dataSourceForAttendance = [];
    } else {
      console.log('Invalid tab selected or no children data.');
    }
  }
}
