import { Component, AfterViewInit, ViewChild, ElementRef } from '@angular/core';

@Component({
  selector: 'app-store-page',
  templateUrl: './store-page.component.html',
  styleUrls: ['./store-page.component.scss']
})
export class StorePageComponent implements AfterViewInit {
  @ViewChild('slider', { static: true }) slider: ElementRef<HTMLDivElement>;

  reviews = [
    {
      name: 'Priya Sharma',
      condition: '16 years old, School Team Captain',
      image: 'assets/images/website/avatar1.png',
      feedback:
        'Uploading my match highlights on YFT not only got me noticed but also helped me understand what scouts look for. I love how easy it is to track my stats and share my growth story.'
    },
    {
      name: 'Vivek Nair',
      condition: 'U17 Player',
      image: 'assets/images/website/avatar2.png',
      feedback:
        'Before YFT, I didn’t know how to track my progress or showcase my best moments. Now I can see my match highlights and review my growth easily. It’s given me a lot of confidence.'
    },
    {
      name: 'Arjun Malhotra',
      condition: '13 years old, Academy Player',
      image: 'assets/images/website/avatar3.png',
      feedback:
        'YFT helped me build my profile and showcase my skills to my dream academies. My coach noticed how I improved after tracking my progress, and I got selected for the starting lineup!"'
    },
    {
      name: 'Sneha Iyer',
      condition: '14 years old, State-Level Midfielder',
      image: 'assets/images/website/avatar1.png',
      feedback:
        'YFT became my go-to platform during my training breaks. I started tracking my dribbling, passes, and fitness progress. When scouts reached out after viewing my videos, I knew it was a game-changer!'
    },
    {
      name: 'Rohan Kapoor',
      condition: '16 years old, District Midfielder',
      image: 'assets/images/website/avatar2.png',
      feedback:
        "I  used YFT to upload my match clips, and I got invited for a trial! It's awesome to see my improvement and get feedback."
    },
    {
      name: 'Aditya Mehra',
      condition: '18 years old, State-Level Forward',
      image: 'assets/images/website/avatar3.png',
      feedback:
        'YFT made it easier to get noticed. The detailed breakdown of my performance helped me focus on my weak spots and level up.'
    },
    {
      name: 'Ishaan Gupta',
      condition: '20 years old, Amateur League Defender',
      image: 'assets/images/website/avatar2.png',
      feedback:
        'I love how YFT lets me showcase my skills and track my stats in one place. I’ve already had scouts reach out for trial opportunities!'
    }
  ];
  constructor() {}

  ngOnInit() {}

  autoScrollInterval: any;

  ngAfterViewInit(): void {
    this.enableAutoScroll();
  }

  enableAutoScroll(): void {
    const sliderElement = this.slider.nativeElement;

    this.autoScrollInterval = setInterval(() => {
      sliderElement.scrollBy({
        left: 300, // Adjust scroll amount based on card width
        behavior: 'smooth'
      });

      // Reset scroll position when reaching the end
      if (
        sliderElement.scrollLeft + sliderElement.clientWidth >=
        sliderElement.scrollWidth
      ) {
        sliderElement.scrollTo({
          left: 0,
          behavior: 'smooth'
        });
      }
    }, 3000); // Change every 3 seconds
  }

  ngOnDestroy(): void {
    // Clear the interval to avoid memory leaks
    if (this.autoScrollInterval) {
      clearInterval(this.autoScrollInterval);
    }
  }
}
